.sidebarLogo {
  @apply h-14;
  @apply w-14;
}

.sidebar {  
  @apply bg-black;
  @apply text-white;
}

.title {
  @apply text-xl;
  @apply font-bold;
  @apply leading-loose;
  @apply mt-3;
}

.divider {
  @apply w-[90%];
  @apply space-y-2;
  @apply border-t;
  @apply border-gray-700;
}