.table {
  @apply flex flex-col;
  @apply overflow-y-hidden overflow-x-auto;
  @apply table-fixed;
}

.tableHeadCell {
  @apply bg-black text-white;
  @apply text-sm font-normal text-left;
  @apply p-2;
}

.tableDataCell {
  border-bottom-style: solid;

  @apply p-1;
  @apply border-b-gray-200 border-b-2;
  @apply text-sm font-normal;
  @apply whitespace-nowrap;
}

.tableDataRow:last-child td th {
  @apply border-0;
}

.tableDataRow:nth-of-type(odd) {
  @apply bg-gray-100;
}