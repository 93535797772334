.linkCard {
  @apply flex;
  @apply flex-row;
  @apply items-center justify-center;
  @apply align-middle;
  @apply rounded-md;
  @apply no-underline;
  @apply my-1;
  @apply h-14;
}

.linkCard:hover {
  @apply bg-gray-200;
}

.linkCardActive {
  @apply flex;
  @apply flex-row;
  @apply items-center justify-center;
  @apply rounded-md;
  @apply no-underline;
  @apply my-1;
  @apply h-14;
  @apply bg-gray-200;
}