@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-sans;
  @apply subpixel-antialiased;
}

.appWindow {
  @apply ml-20;
}

.dataWindow {
  @apply flex flex-col;
  @apply p-3;
}

.editWindow {
  @apply flex flex-row;
  @apply p-3;
}

.editWindowData {
  @apply flex flex-col;
  @apply p-12;
  @apply text-xl;
}

.editWindowData label {
  @apply inline-block;
  @apply w-52;
}

.editWindow input {
  @apply w-80;
}

.title {
  @apply font-bold;
  @apply text-4xl;
  @apply py-5;
}

.button,
.searchInput {
  /* @apply bg-black text-white; */
  @apply items-center justify-center;
  @apply inline;
  @apply leading-5;
  @apply py-2 px-5;
  @apply ml-4 mb-4;
  @apply transition-all;
  @apply text-base;
}

.button:hover {
  @apply bg-gray-500;
}

.searchToolbar {
  @apply flex;
  @apply flex-row;
}

.deleteDialog {
  @apply hidden;
  @apply overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50;
}